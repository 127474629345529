<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-solution-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="row justify-content-lg-between align-items-lg-center">
          <div class="col-md-6">
            <div class="mb-5 mb-md-0">
              <span class="d-block small font-weight-bold text-cap mb-2">our solutions</span>
              <h1>API-First Architecture</h1>
              <p class="lead">Seamlessly Integrated. Highly Performant.</p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="w-90 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/api-product.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">About</span>
        <h2>What is API-First Architecture?</h2>
        <p>Embrace the power of API-First Architecture to revolutionize your enterprise connectivity and scalability.</p>
      </div>

      <div class="row align-items-lg-center mb-6">
        <div class="col-md-6 order-md-1 mb-3">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/consultancy-overview-2.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Streamlined API Solutions</h3>
          <p>Seamlessly integrate and enhance performance with tailored API solutions for modern enterprises. Our API-first approach ensures efficient connectivity, enabling you to build, deploy, and manage APIs with ease, driving maximum value for your business.</p>
        </div>
      </div>

      <div class="row align-items-lg-center mb-6">
        <div class="col-md-6 mb-3">
          <div class="w-80 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/trust-hero.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Secure, Scalable API Gateway</h3>
          <p>Leverage our cloud-native API gateway and infrastructure to power your digital ecosystem. Our robust, secure, and scalable solutions provide the foundation for your API-driven initiatives, ensuring reliable and high-performing connections.</p>
        </div>
      </div>

      <div class="row align-items-lg-center mb-6">
        <div class="col-md-6 order-md-1 mb-3">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/consultancy-overview-1.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Custom API Development</h3>
          <p>Unlock new possibilities with our expert-crafted API development services. We work closely with your team to design, implement, and maintain custom APIs that address your unique business requirements, driving innovation and improving integration.</p>
        </div>
      </div>

      <div class="row align-items-lg-center mb-6">
        <div class="col-md-6 mb-3">
          <div class="w-90 w-lg-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-management.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Strategic API Planning</h3>
          <p>Maximize the potential of your API with comprehensive strategy and ecosystem development. Our seasoned experts guide you through the entire API lifecycle, from conception to implementation, ensuring your APIs align with your business goals and deliver measurable results.</p>
        </div>
      </div>

      <div class="row align-items-lg-center">
        <div class="col-md-6 order-md-1 mb-3">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/consultancy-overview-3.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Upskill Your Team</h3>
          <p>Empower your developers through hands-on training and API-focused hackathons. We offer tailored training programs to help your team master the latest API best practices, fostering a culture of innovation and continuous improvement.</p>
        </div>
      </div>
    </div>
    <!-- ========== End Overview Section ========== -->

    <!-- ========== Overveiw Section ========== -->
    <div class="bg-img-hero-center" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Overview</span>
          <h2>What makes beNovelty different in API Consultancy?</h2>
        </div>

        <div class="row align-items-lg-center mb-6">
          <div class="col-md-6 order-md-1 mb-3">
            <div class="w-80 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/kong-apiops.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3>beNovelty is beyond a pure consultant, we operate business API platforms as well for years</h3>
            <p>beNovelty is experienced in operating multiple business API platforms, including its own OpenAPIHub. The team can feel your pains and share the most practical recommendations in your end to end API journey.</p>
          </div>
        </div>

        <div class="row align-items-lg-center mb-6">
          <div class="col-md-6 mb-3">
            <div class="w-80 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/kong-environments.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3>beNovelty is beyond a pure technology company, we have extensive API partners network to boostrap your API strategy</h3>
            <p>beNovelty runs a leading API community in Hong Kong and is the local organizer of Apidays Hong Kong. The team is also experienced in running various API innovation programmes including the HSBC API EcoBooster - the largest Open Banking Innovation Programme in Hong Kong in 2020.</p>
          </div>
        </div>

        <div class="row align-items-lg-center mb-6">
          <div class="col-md-6 order-md-1 mb-3">
            <div class="w-80 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-product.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3>beNovelty's API capability is well recognized by leading technology partners</h3>
            <p>beNovelty is an offical partner of Kong Enterprise, F5/Nginx, Amazon Web Services (AWS) , Microsoft Azure &amp; Alibaba Cloud. Please let us know if you would like to partner with us.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Overview Section ========== -->

    <!-- ========== consulting journey Section ========== -->
    <div class="">
      <div class="container space-2 space-lg-3">
        <div class="w-md-75 w-lg-80 text-center mx-auto mb-5 mb-md-8">
          <span class="d-block small font-weight-bold text-cap mb-2">our end-to-end approach</span>
          <h2>beNovelty's Enterprise API Consultancy Lifecycle</h2>
        </div>
        <div class="w-90 w-md-80 w-lg-60 mx-auto">
          <img class="img-fluid" src="@/assets/svg/illustrations/api-consultancy-step.svg">
        </div>
      </div>
    </div>
    <!-- ========== End consulting journey Section ========== -->

    <!-- ========== More Solutions Section ==========-->
    <div
      class="space-2 space-lg-3 bg-blue-light"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="position-relative">
        <div class="container">
          <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Don't Want to Manage on Your Own?</span>
            <h2>Ready-Made API-First SaaS Solutions</h2>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixapi' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-fabrixapi-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>API Management Platform</h3>
                  <p><strong>FabriXAPI</strong> is a one-stop API platform for providers to build and share APIs.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/openapihub' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-openapihub-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>Leading API Community</h3>
                  <p><strong>OpenAPIHub</strong> is the leading API community with a powerful API directory and ecosystem.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fiberconnect' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-fiberconnect-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>API Connectivity for Financial Services</h3>
                  <p><strong>FiberConnect</strong> uses Open API to boost SME & Startup efficiency with QR Code payments.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

    <!-- ===== CTA Section ===== -->
    <div class="position-relative overflow-hidden text-center">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Revolutionize Your Enterprise with API-First Architecture</h2>
          <p>Contact Us to explore how our solutions can revolutionize your business.</p>
        </div>
        <router-link class="btn btn-primary transition-3d-hover btn-wide" :to="{ path: '/contact' }">Contact Us</router-link>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'ApiFirstArchitecture',
  data() {
    return {
      api_partner_list: []
    }
  },
  computed: {
    ...mapGetters([
      'partners'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
    this.api_partner_list = this.$store.getters.partners.api_partner_list
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'API-First Architecture | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/api-first-architecture' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'API-First Architecture | Solutions | beNovelty' },
        { property: 'og:description', content: 'Discover the API-First Architecture Solutions offered by beNovelty.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/api-first-architecture' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
